.action-card {
	--icon-size: 25%;
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	text-decoration: none;
	font-weight: 500;
	font-size: var(--fluid-body-sm);
	color: $primary-color;
	border: 1.5px solid $primary-color;
	border-radius: 30px;
	overflow: hidden;
	padding: .8rem .4rem;


	.action-title {
		font-weight: 500;
		text-transform: uppercase;
		font-size: var(--fluid-body-xs);
	}

	.action-tagline {
		margin-top: .2rem;
		color: $black;
		font-weight: 500;
		font-size: var(--fluid-body-xs);
	}

	.action-icon-wrap {
		width: var(--icon-size);
		margin-left: -5%;
		opacity: .5;
		transition: opacity .3s ease;
		flex-shrink: 0;
		z-index: 0;
	}


	&:after {
		content: '';
		//background-color: $primary-color;
		background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(239,71,47,.4) 100%);
		position: absolute;
		border-radius: 30px;
		bottom: 0;
		left: 0;
		height: 25%;
		width: 100%;
		transition: all .5s ease;
		opacity: 0;
	}


	&:hover, &:active, &:focus {
		.action-icon-wrap {
			opacity: .7;
		}

		&:after {
			opacity: .5;
		}
	}

	&:active {
		.action-icon-wrap {
			opacity: .7;
		}

		&:after {
			opacity: 1;
		}
	}
}
