.wpum-template {
	input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="number"], input[type="tel"], input[type="range"], input[type="date"], textarea, select {
		display: flex;
		width: 100%;
		border: 1.5px solid rgba($black, 50%);
		border-radius: 30px;
		box-shadow: 2px 2px 5px rgba($black, .2);
		background: rgba(255, 255, 255, .9);
		font-size: var(--fluid-body);
		transition: all .3s ease;
		padding: .3em 1em;

		&:focus {
			background: white;
			outline: 0 !important;
			outline-color: transparent !important;
			box-shadow: 2px 2px 10px rgba($black, .3);
			//box-shadow: 2px 2px 8px rgba($black, .2);
		}
	}

	label {
		font-size: var(--fluid-body-sm);
	}


	input[type="submit"] {
		@extend .fancy-btn;
		@extend .btn-solid;
	}
}


.wpum-login-form {
	label {
		color: white;
	}
}


.acf-input-wrap {
	overflow: visible;
}


input[type="submit"] {
	cursor: pointer;
}

.tab-view {
	display: none !important;
}
