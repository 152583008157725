#highlighted-resources {
	padding-left: 0;
	padding-right: 0;
	.highlighted-title {
		color: $black;
	}

	.slider-wrap {
		background-color: $light-gray;
		border-radius: 30px;
		padding: 50px 20px;

		@include mq(m) {
			padding: 50px 30px;
		}
	}

	.slider-arrow-wrap {
		position: relative;
		padding-left: 20px;
		padding-right: 20px;
		margin-top: 40px;

		@include mq(m) {
			padding-left: 30px;
			padding-right: 30px;
		}

		.swiper-button-prev {
			left: -15px;
		}

		.swiper-button-next {
			right: -15px;
		}

		.swiper-button-next, .swiper-button-prev {
			--swiper-navigation-size: 50px;
			--swiper-navigation-color: white;
			//background-color: $primary-color;
			font-weight: bold;
			//padding: .3em;
			&:after {
				content: '';
				//padding: 5px;
			}
		}

		.swiper-pagination {
			bottom: -10px;

			--swiper-theme-color: #{$primary-color};
			--swiper-pagination-bullet-size: 10px;
		}
	}

	// slider specific edits

	.resource-excerpt {
		font-size: var(--fluid-body-sm);
	}

}

.resource-single {
	display: flex;
	// if images need to come back change this to row wrap
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	gap: 10px;


	// Control wraps
	.resource-image-wrap {
		flex-basis: 20%;
		min-width: 175px;
		max-width: 250px;
		flex-grow: 1;
		order: -1;

		@include mq(m) {
			order: 1;
		}
	}

	.content-wrap {
		display: flex;
		flex-flow: column nowrap;
		flex-basis: 75%;
		flex-grow: 1;
		width: fit-content;
		text-decoration: none;
		color: $dark-gray;

		&:hover, &:active &:focus {
			.action-title {
				color: $primary-color;
				&:before {
					background-color: $primary-color;
				}
			}
		}

		&:active {
			.action-title {
				color: $primary-color-dark;
				&:before {
					background-color: $primary-color-dark;
				}
			}
		}
	}


	.action-title {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		align-content: center;
		justify-items: center;
		line-height: 1.1;
		font-weight: bold;
		transition: all .3s ease;

		@include mq(m) {
			line-height: 1;
		}


		font-size: var(--fluid-body-sm);
		text-transform: uppercase;
		color: $medium-gray;
		&:before {
			content: '';
			display: block;
			background: $gray;
			height: 1em;
			width: 1em;
			border-radius: 1em;
			margin-top: .2em;
			margin-bottom: .2em;
			margin-right: .5em;
			flex-shrink: 0;
			transition: all .3s ease;
		}
	}

	.resource-title {
		color: $black;
		margin-top: .2em;
		margin-bottom: .2em;
	}

	.resource-excerpt {
		color: $black;
		font-weight: 400;

		p {
			display: block;
			margin: .5em 0;
		}
	}

	.resource-author {
		font-size: var(--fluid-body-sm);
		color: $primary-color;
		font-weight: 500;
		margin-top: .6em;
	}

	.image-aspect-wrap {
		position: relative;
		width: 100%;
		padding-bottom: 100%;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-position: center center;
			object-fit: cover;
			border-radius: 20px;
		}
	}
/*
	.separator {
		display: flex;
		align-self: flex-start;
		justify-self: flex-start;
		text-align: left;
		height: 1px;
		background: $primary-color;
		width: 35%;
		margin: 1.5rem 0;

	}*/

	.category-list {
		position: relative;
		display: flex;
		flex-flow: row wrap;
		gap: 5px;
		padding-top: 1em;
		padding-left: 0;
		list-style: none;
		margin-top: .5em;


		.fancy-btn {
			font-size: var(--fluid-body-xs);
			padding: 1em 1.3em;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 5px;
			width: 8rem;
			height: 1px;
			background: $primary-color;;
		}
	}
}
