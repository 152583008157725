.wp-block-pb-accordion-item {
	display: block;
	margin: 10px 0;
	.c-accordion__title {
		font-size: var(--fluid-h5);
		font-weight: 600;

		border: 1px solid $primary-color;
		border-radius: 30px;
		padding: 12px 20px;
		padding-right: 45px;
		transition: all 0.3s ease;
		&:hover, &[aria-expanded="true"] {
			background-color: $primary-color;
			color: white;

			&:after {
				color: white;
			}
		}

		&:after {
			color: $dark-gray;
			font-weight: 500;
			font-size: var(--fluid-h2);
			right: 15px;
		}
	}

	.c-accordion__content {
		padding-left: 20px;
	}
}
