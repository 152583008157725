#resource-hero {
	background-position: center center;
	background-size: cover;
	height: 300px;
	@include mq(m) {
		height: 350px;
	}

	@include mq(l) {
		height: 400px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

#single-resource {
	position: relative;
	padding-bottom: 0;
	.fancy-dots {
		position: relative;
		width: 80%;
		margin: 0 auto;
		margin-top: 1.5rem;
		height: 150px;

		@include mq(1600px) {
			position: absolute;
			bottom: -25%;
			left: 0;
			width: calc(50% - 600px);
			height: 80%;

		}
	}

	.co-author-wrap {
		align-self: flex-start;
		justify-self: flex-start;
		margin-right: auto;
	}

	--section-width: 1000px;
	.essential-action-title {
		display: flex;
		align-items: center;
		font-size: var(--fluid-body);
		font-weight: bold;
		//color: $primary-color;
		margin-bottom: .5rem;
		align-self: flex-start;
		width: auto;

		@include mq(m) {
			font-size: var(--fluid-h6);
		}

		.icon {
			display: flex;
			height: 2em;
			margin-right: .5em;

			svg {
				height: 100%;
			}
		}
	}

	.resource-title {
		color: $black;
		font-size: var(--fluid-h4);

		@include mq(m) {
			font-size: var(--fluid-h3);
		}
	}

	.resource-asset {
		align-items: center;
		justify-content: center;
		text-align: center;
		width: max-content;
		flex-grow: 1;

		a.fancy-btn {
			justify-content: center;
			text-align: center;
			width: 100%;
			font-size: var(--fluid-body-sm);
		}

		@include mq(m) {
			width: auto;
			flex-grow: unset;
			a.fancy-btn {
				font-size: var(--fluid-body-xs);
			}
		}
		.icon {
			height: 1.5em;
			svg	{
				height: 100%;
				color: currentColor;
			}
		}
	}

	.info-grid {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 20px;
		row-gap: 10px;
		justify-content: space-between;
		font-size: var(--fluid-body-sm);
		font-weight: 500;
		border-bottom: 1.5px solid $primary-color;
		padding-bottom: 1rem;

		.date {

			font-weight: 500;
			padding-right: 1rem;
		}

		.author {
			color: $primary-color;
			margin-right: auto;
			font-weight: 600;
		}

		.fancy-btn {
			font-size: var(--fluid-body-xs);
		}

		.soc-external {
			display: flex;
			align-items: center;
			color: $secondary-color;
			font-weight: 600;

			.label {
				padding-right: .5em;
			}
		}

		.categories {
			display: flex;
			flex-flow: row wrap;
			list-style: none;
			max-width: 100%;
			gap: 5px;
			padding-left: 0;
			width: 100%;

			@include mq(m) {
				min-width: 60%;
				max-width: 60%;
			}
			a {
				font-size: var(--fluid-body-xs);
				padding: 1em 1.3em;
			}
		}
	}

	.resource-wrap {
		display: flex;
		flex-flow: column nowrap;
	}

	.resource-content {
		margin-top: 1rem;
		display: flex;
		flex-flow: column nowrap;

		.resource-asset {
			margin-top: 1rem;
			align-self: center;
		}
	}
}
