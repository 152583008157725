.s-section, .wp-block-group, .wp-block-cover {
	--section-width: #{$global-width};
	--padding-horizontal: 20px;
	--padding-vertical: 40px;

	padding-top: var(--padding-vertical);
	padding-bottom: var(--padding-vertical);
	--wp--style--block-gap: 20px;
	display: grid;
	grid-template-columns: minmax(var(--padding-horizontal), 1fr) minmax(0, var(--section-width)) minmax(var(--padding-horizontal), 1fr);

	grid-template-areas: "start content end";
	width: 100%;
	/**
	* Forces all first level children
	* to align to the center column that has minmax to create a gutter
	**/
	> * {
		grid-column: content / span 1;
	}

	.full-width {
		grid-column: start / end;
	}

	.alignwide {
		grid-column: span 3;
		max-width: var(--global-width-wide);
		justify-self: center;
		width: 100%;
	}

	.alignfull {
		position: relative;
		grid-column: start / end;
		width: calc(100% + (var(--padding-horizontal) * 4));
	}


	&.is-position-center-right {
		justify-items: flex-end;
		align-content: center;
	}

	&.is-position-center-left {
		justify-items: flex-start;
		align-content: center;
	}

	&.is-position-center-center {
		justify-items: center;
		align-content: center;
	}

	&.is-position-bottom-center {
		justify-items: center;
		align-content: flex-end;
	}

	&.is-position-bottom-right {
		justify-items: flex-end;
		align-content: flex-end;
	}
	&.is-position-bottom-left {
		justify-items: flex-start;
		align-content: flex-end;
	}

	&.is-position-top-center {
		justify-items: center;
		align-content: flex-start;
	}

	&.is-position-top-left {
		justify-items: flex-start;
		align-content: flex-start;
	}

	&.is-position-top-right {
		justify-items: flex-end;
		align-content: flex-start;
	}
}

.gutenberg-content {
	padding: unset;
	gap: var(--wp--style--block-gap);
	grid-template-columns: minmax(0, 1fr) minmax(0, var(--section-width)) minmax(0, 1fr);


	// Overwrite section padding
	.s-section {
		padding-left: 0;
		padding-right: 0;
	}

	> * {
		max-width: 100%;
		width: $global-width;
	}
}

.remove-block-gap {
	//margin-top: calc(-1 * var(--wp--style--block-gap));
	margin-bottom: calc(-1 * var(--wp--style--block-gap));
}
