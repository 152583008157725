.login-section {
	border-bottom: 2px solid white;
	//margin-bottom: -50px;
	z-index: 1;

	.login-group {
		padding-bottom: 100px;
		padding-top: 100px;
		margin-bottom: -40px;
	}

	.wp-block-columns {
		padding-top: 30px;
		--wp--style--block-gap: 40px;

		h1 {
			margin-bottom: 50px;
		}
	}


	.wpum-already-logged-in {
		padding: 30px;
		border-radius: 15px;

		background: $light-gray;
		color: $black;
		display: flex;
		p {
			display: flex;
			width: 100%;

			a:first-child {
				margin-left: auto;
			}
		}
	}
}

.wpum-account-page {
	.fieldset-user_displayname, .fieldset-user_lastname, .fieldset-user_nickname, .fieldset-user_description, .fieldset-user_website{
		display: none;
	}

	.acf-field {
		padding: 30px 12px;
	}

	.acf-label {
		label {
			font-size: var(--fluid-h5);
		}

		.description {
			font-size: var(--fluid-body-sm);
		}
	}
}
