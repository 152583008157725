.body-controller {
	overflow-x: hidden;
	display: flex;
	flex-flow: column nowrap;
	min-height: 100vh;

	footer {
		margin-top: auto;
	}
}


body {
	display: flex;
	flex-flow: column nowrap;
	margin: 0;

	&:not([data-s-loaded]) {
		* {
			transition: none !important;
		}
	}
}


.resource-content, .content {
	display: flex;
	flex-flow: column nowrap;
}

svg {
	height: 100%;
}
