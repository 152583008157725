fancy-dots {
	position: relative;
	--dot-spacing: 1.5rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(var(--dot-spacing), var(--dot-spacing)));
	grid-template-rows: repeat(auto-fit, minmax(var(--dot-spacing), var(--dot-spacing)));

	width: 9em;
	height: 18em;

	z-index: 1;

	[data-dot] {
		position: relative;
		z-index: 1;
		margin: 0;
		align-self: center;
		justify-self: center;
		padding: calc(var(--dot-spacing) / 2);
		transition: all 1.5s cubic-bezier(0.68, -0.4, 0.32, 2);

		color: $gray;
		border-radius: 100px;

		&:before {
			content: '';
			position: relative;
			display: block;
			background: currentColor;
			border-radius: 100px;
			width: calc(var(--dot-spacing) / 3);
			height: calc(var(--dot-spacing) / 3);
			transform: scale(.4);
			transition: all 1.5s cubic-bezier(0.68, -0.4, 0.32, 2);
			z-index: -1;
		}

		&.blue {
			color: $secondary-color;
		}

		&.red {
			color: $primary-color;
		}

		&.dark-red {
			color: $primary-color-dark;
		}

		&.dark-blue {
			color: $secondary-color-dark;
		}

		&.gray {
			color: $gray;
		}

		&.dark-gray {
			color: $dark-gray;
		}

		&[active] {
			&:before {
				transform: scale(.75);
			}
		}

		&:hover {
			&:before {
				transition: all .2s ease;
				transform: scale(1);
				color: $primary-color;
			}
			transition: all .3s ease;
		}
	}
}
