#footer {
	background: $black;
	padding-bottom: 0;
	overflow: hidden;

	.footer-grid {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;
		width: 300px;
		gap: 20px;
		max-width: 100%;
		justify-self: center;

		@include mq(m) {
			width: 100%;
			flex-flow: row wrap;
		}
	}

	a {
		color: white;
		text-decoration: none;
	}

	#footer-menu {
		ul {
			display: flex;
			flex-flow: column nowrap;
			gap: 20px;
			margin: 0 auto;
			justify-content: center;
			align-items: center;
			@include mq(m) {
				flex-flow: row wrap;
				gap: 10px;
			}
		}

		a {
			padding: 10px;
			font-weight: bold;
			text-transform: uppercase;
			text-align: center;

			&:hover, &:active, &:focus {
				color: $secondary-color;
			}

			&:active {
				color: $secondary-color;
			}
		}
	}

	.logo-wrap {
		order: -1;
		margin-bottom: 30px;
		width: 100%;

		@include mq(l) {
			order: unset;
			width: auto;
		}
		img {
			height: 130px;
		}
	}

	.soc-grid {
		margin-right: unset;
		margin-left: unset;

		@include mq(m) {
			margin-left: 20px;
			margin-right: auto;
		}

	}

	.fancy-dots {
		width: 100%;
		height: 250px;
		margin-top: 1rem;
		margin-bottom: -1rem;
		z-index: 1;
		@include mq(m) {
			height: 225px;
			width: 75%;
		}
		@include mq(l) {
			width: 60%;
		}
	}
}


// abbreviate name so ad blockers don't hide it
.soc-grid {
	display: flex;
	align-items: center;
	align-content: center;
	justify-items: center;
	--icon-size: var(--fluid-h5);
	--icon-color: #{$primary-color};
	--icon-padding: 10px;
	//margin-left: calc(-1 * var(--icon-padding));
	//margin-right: calc(-1 * var(--icon-padding));
	a {
		text-decoration: none;
	}

	.soc-icon {
		padding: var(--icon-padding);

		svg {
			height: var(--icon-size);
			color: var(--icon-color);
			width: auto;
		}

		&.soc-dark {
			--icon-size: calc( var(--fluid-h5) + 6px);
			--icon-color: #{$tertiary-color};

			&:hover, &:active, &:focus {
				svg {
					color: #{$tertiary-color-light} !important;
				}
			}
		}
	}
}
