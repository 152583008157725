.mini-categories {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	button {
		margin: 4px;
		flex-flow: row nowrap;
		background-color: $primary-color;
		color: white;
		cursor: pointer;
		strong {
			margin-right: 0.25rem;
			margin-bottom: 0;
		}

		&:hover {
			background-color: $primary-color-dark;
			color: white;
		}

		svg {
			margin-left: 0.2rem;
			height: 1.6em;
		}
		font-size: 12px;
		font-weight: 700;
		padding: 8px 12px;

		&[data-group="essential-actions"] {
			background-color: unset !important;
			color: $primary-color !important;
		}
	}
}

.filter-group {
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	justify-content: flex-start;

	.group-title {
		width: 100%;
	}
}

custom-search {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	max-width: 800px;
	justify-self: center;
	column-gap: 1.5rem;
	row-gap: .5rem;

	@include mq(m) {
		flex-flow: row nowrap;
		.fancy-btn {
			width: auto;
		}
	}


	.fancy-btn {
		text-align: center;
		justify-content: center;
		width: 100%;
		max-width: 350px;
		flex-shrink: 0;
		align-self: center;
		font-size: var(--fluid-body-sm);
		padding: .8rem 1.5rem;
	}
}
.search-wrap {
	display: flex;
	width: 100%;
	border: 1.5px solid rgba($black, 50%);
	border-radius: 8px;
	box-shadow: 2px 2px 5px rgba($black, .2);
	background: rgba(255, 255, 255, .9);
	font-size: var(--fluid-h6);
	transition: all .3s ease;
	@include mq(m) {
		font-size: var(--fluid-h5);
	}

	&:focus-within {
		background: white;
		outline: 3px solid rgba($secondary-color-dark, 40%);
		box-shadow: 2px 2px 8px rgba($black, .2);
	}

	.search-label {
		display: none;
		visibility: hidden;
		width: 0;
		height: 0;
		opacity: 0;
	}

	.search-bar {
		width: 100%;
		background: unset;
		padding: 12px;
		border: unset;
		outline: unset;
		font-size: inherit;
		font-weight: 400;
		flex-grow: 1;
		order: 2;
		&::placeholder {
			color: darken($gray, 20%);
			font-weight: 500;
		}
	}

	.search-button {
		font-size: var(--fluid-h5);
		display: flex;
		order: 1;
		padding: 12px;
		background: unset;
		border-radius: 6px;
		border: unset;
		cursor: pointer;
		color: $black;
		transition: all .3s ease;
		svg {
			height: auto;
			width: 1.5em;
			transition: all .3s ease;
		}

		&:hover, &:focus, &:active {
			color: lighten($black, 5%);
		}

		&:focus {
			svg {
				filter: drop-shadow(0 0 8px rgba($gray, 1));
			}
			outline: 0;
			//box-shadow: inset 0 0 0 3px rgba($primary-color, .3);
		}


	}
}

.author-filter-wrap {
	width: 100%;
}

.author-filter {
	width: auto;
	align-self: flex-start;
	border: 2px solid $primary-color;
	border-radius: 100px;
	padding: .5rem;
	max-width: 300px;
	width: 100%;
	margin-bottom: 1rem;
	appearance: none;

	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHZpZXdCb3g9IjAgMCAxOTkuNCAxOTkuNCI+DQoJPHBhdGggZD0ibTE5OS40IDYzLjk5LTI4LjI4LTI4LjI4LTcxLjQyIDcxLjQyLTcxLjQyLTcxLjQyTDAgNjMuOTlsOTkuNyA5OS43eiIgLz4NCjwvc3ZnPg==");
	background-repeat: no-repeat;
	background-position: right .8em top 50%, 0 0;
	background-size: 1em auto, 100%;

}
