/**
 * Buttons
 **/

.fancy-btn, .filter-button, .update-filter-button, .clear-filter-button {
	position: relative;
	display: inline-flex;
	align-items: center;
	padding: .9em 2.4em;
	line-height: 1.2;
	background: white;
	font-weight: 500;
	box-shadow: inset 0 0 0 1px $primary-color;
	border: unset;
	border-radius: 200px;
	color: $primary-color;
	text-decoration: none;
	transition: all .3s ease;
	overflow: hidden;
	z-index: 1;

	&:before {
		content: '';
		height: calc(100% + 2px);
		width: 0;
		position: absolute;
		background: $primary-color;
		border-radius: 1000px;
		z-index: -1;
		top: -2px;
		left: -2px;
		transition: all .3s ease;
	}

	// hover states
	&:hover, &:focus, &:active, &.selected {
		color: $white;
		&:before {
			width: calc(100% + 2px);
		}
	}

	// active state - click
	&:active, &.selected {
		color: white;
		&:before {
			background: darken($primary-color-dark, 10%);
		}
	}

	&.btn-solid {
		background: $primary-color;
		color: white;
		font-weight: bold;
		&:hover, &:focus, &:active {
			background: darken($primary-color-dark, 5%);
		}
	}

	&.btn-narrow {
		padding: 0.8em 1.6em;
	}

	&.btn-dark {
		background: $black;
		color: $white;
		font-weight: bold;
		box-shadow: inset 0 0 0 1px $black;

		&:before {
			background: lighten($black, 10%);
		}

		&:hover, &:focus, &:active {
			background: darken($black, 5%);
		}

		&:active {
			&:before {
				background: lighten($black, 5%);
			}
		}
	}

	&.btn-square {
		border-radius: 0;

		&:before {
			border-radius: 0;
		}
	}

	.icon {
		margin-right: .5em;
	}
}


.wp-block-buttons, .wp-block-button {
	display: flex;
	flex-flow: row wrap;
	gap: 10px;
	margin-top: .5rem;
	margin-bottom: 1rem;
	.wp-block-button__link {

		font-size: var(--fluid-body-sm);
		@extend .fancy-btn;
		@extend .btn-solid;
	}
}

.filter-button {
	flex-flow: column nowrap;
	strong {
		margin-bottom: .2rem;
	}
}
