#search-hero {
	box-shadow: inset 0 0 0 20000px rgba(255,255, 255, .3);
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: contain;
	padding-top: 200px;
	padding-bottom: 10px;

	@include mq(m) {
		padding-top: 275px;
	}

	@include mq(l) {
		padding-top: 325px;
	}
}

#filters-section {
	padding-top: 0;
}

.filter-accordion {
	display: flex;

	summary {
		color: $primary-color;
		font-size: var(--fluid-body);
		font-weight: 500;
		justify-self: flex-start;
		align-self: flex-start;
		width: max-content;
		padding: 10px;
		cursor: pointer;
		transition: all .3s ease;

		&::marker {
			font-size: 90%;
		}

		&:hover, &:active, &:focus {
			color: $primary-color-dark;
			filter: drop-shadow(0 0 4px rgba($gray, .4));
		}
	}

	#filter-form {
		display: grid;
		gap: 30px 20px;
		grid-template-columns: 1fr;
		margin-top: 1rem;

		@include mq(m) {
			grid-template-columns: 1fr 1fr;
		}


		.group-title {
			width: 100%;
			margin-bottom: 15px;
			font-weight: 500;
			font-size: var(--fluid-body-sm);
			color: $dark-gray;
		}

		.filter-group-options {
			--grid-gap: 5px;
			display:flex;
			flex-flow: row wrap;
			margin: calc(-1 * var(--grid-gap));

			.filter-button {
				z-index: 0;
				margin: calc(var(--grid-gap) * .5);
				font-size: calc(1 * var(--fluid-body-xs));
				max-width: 325px;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				/*&:hover {
					color: $primary-color;
					&:before {
						width: 0;
					}
				}

				@media (hover: hover) and (pointer: fine) {
					&:hover {
						color: white;
						&:before {
							width: calc(100% + 2px);
						}
					}
				}*/
			}
		}
		.hidden-btn {
			display: none;
		}


	}
	.update-filter-button, .clear-filter-button {
		margin-top: 2rem;
		font-size: var(--fluid-body-xs);
		&.hidden {
			display: none;
		}
	}
	.open-text, .close-text {
		display: inline-flex;
		opacity: 0;
		width: 0;
		height: 0;
	}

	&[open] {
		.open-text {
			opacity: 1;
			width: auto;
			height: auto;
		}
	}

	&:not([open]) {
		.close-text {
			opacity: 1;
			width: auto;
			height: auto;
		}
	}
}

.touch {
	.filter-button {
		&:hover {
			color: $primary-color;
			&:before {
				width: 0;
			}
		}

		&:focus, &:active {
			color: $white;
			&:before {
				width: calc(100% + 2px);
			}
		}
	}
}



#essential-search {
	padding-top: 50px;
	padding-bottom: 50px;
	.essential-title {
		margin-bottom: 1rem;
		font-weight: 600;
		color: $primary-color;
	}

	.search-grid {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		gap: 10px;
		max-width: 450px;

		@include mq(m) {
			max-width: 100%;
		}

		.action-card {
			width: 100%;
			overflow: hidden;

			.action-title {
				font-size: calc(var(--fluid-body-xs) * 1.15);
				font-weight: 600;
				margin-bottom: .1rem;
			}
			.action-tagline {
				font-size: var(--fluid-body-xs);
			}

			@include mq(m) {
				width: calc(50% - 10px);
			}

			@include mq(l) {
				width: calc(33% - 10px);
			}
		}
	}
}



#introduction {
	position: relative;
	padding-bottom: 20px;
	.fancy-dots {
		display: none;
		&.right-dots {
			display: grid;
			position: relative;
			height: 100px;
			margin-top: 20px;
			width: 80%;
			justify-self: center;
		}

		@include mq(1400px) {
			display: grid;
			position: absolute;
			top: 0;
			left: 0;
			width: 10%;
			height: 100%;

			&.right-dots {
				display: grid;
				position: absolute;
				top: 0;
				left: unset;
				right: 0;
				width: 10%;
				height: 100%;
				margin-top: 0;
			}
		}
	}

	.title-image-wrap {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		justify-items: center;
		align-items: center;
		margin-bottom: 1rem;
	}
	.title-wrap {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;

		@include mq(m) {
			width: 55%;
		}
	}

	.highlighted-title {
		color: $primary-color;
		font-weight: 800;
		text-transform: uppercase;
		margin-bottom: .25rem;
	}

	.intro-title {
		color: $black;
	}

	.intro-title-image {
		display: flex;
		width: 100%;
		max-width: 300px;
		@include mq(m) {
			width: 45%;
			max-width: 100%;
		}
	}
}

// div card with dropshadow and blue button

#essential-actions {
	background-color: $light-gray;
	--section-width: 1400px;

	.essential-title {
		color: $black;
		margin-bottom: 3rem;
		max-width: 100%;
		width: $global-width;
		/*justify-self: center;
		text-align: center;*/
	}

	.action-grid {
		display: grid;
		grid-template-columns: 1fr;
		gap: 12px;
		max-width: 450px;
		margin: 0 auto;


		@include mq(m) {
			max-width: unset;
			grid-template-columns: 1fr 1fr;
			// Enable if it looks better with equal heights
			grid-auto-rows: 1fr;
		}

		@include mq(l) {
			grid-template-columns: 1fr 1fr 1fr;
		}


		.action-card {
			grid-row: span 2;
		}

		.action-card {


			@include mq(m) {

				&:nth-child(2) {
					grid-row: 2 / span 2;
					grid-column: 2;
				}
			}

			@include mq(l) {

				&:first-child {
					grid-row: 2 / span 2;
					grid-column: 1;
				}

				&:nth-child(2) {
					grid-row: 1 / span 2;
					grid-column: 2;
				}

				&:nth-child(3) {
					grid-row: 2 / span 2;
					grid-column: 3;
				}
			}
		}
	}

	.action-card {
		flex-flow: column nowrap;
		background: white;
		--icon-size: 40%;
		padding-left: 8px;
		border: 3px solid $primary-color;
		padding: 1rem;

		@include mq(1400px) {
			--icon-size: 45%;
			padding-left: calc( var(--icon-size) - 15%);

			.action-title {
				font-size: 90%;
			}

			.action-sub-title {
				font-size: 90%;
			}

			.action-excerpt {
				font-size: 85%;
			}


			.action-icon-wrap {
				position: absolute;
				top: 50%;
				left: -5%;
				height: auto;
				width: var(--icon-size);
				opacity: .4;
				z-index: 0;
				transform: translateY(-50%);
				transition: opacity .3s ease;
			}
		}

		.action-icon-wrap {
			opacity: .6;
			position: relative;
			top: 0;
			margin-left: -30px;
			img {
				max-height: 100%;
				align-self: center;
			}
		}

		.action-title-icon-wrap {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			align-self: stretch;
			width: 100%;
			.action-icon-wrap {
				flex-basis: 25%;
				flex-shrink: 0;
			}
		}

		.action-title-wrap {
			display: flex;
			flex-flow: column;
		}

		.action-sub-title, .action-title, .action-link {
			//padding-left: calc(var(--icon-size) - 15%);
		}


		.action-content-wrap {
			display: flex;
			flex-flow: column nowrap;
			flex-grow: 1;
			z-index: 1;
		}

		.action-title {
			font-size: var(--fluid-body-sm);
			font-weight: 600;
		}

		.action-sub-title {
			font-size: var(--fluid-body-sm);
			color: $black;
			font-weight: 600;
		}

		.action-excerpt {
			font-size: var(--fluid-body-sm);
			color: $black;
			font-weight: 400;
			//filter: drop-shadow(1px 1px 5px rgba(0,0,0, .4));

			p {
				margin-top: .5rem;
				margin-bottom: 0;
			}

			@include mq(l) {
				//padding-left: calc(var(--icon-size) - 15%);
			}
		}

		.action-link {
			margin-top: .5rem;
			display: flex;
			align-items: center;
			font-size: var(--fluid-body-sm);
			margin-top: auto;
			padding-top: .5rem;
			.search-arrow {
				svg {
					margin-left: .5em;
					height: 1em;
				}
			}
		}

		&:hover, &:active, &:focus {
			.action-icon-wrap {
				opacity: .7;
			}

			&:after {
				opacity: .5;
			}
		}

		&:active {
			.action-icon-wrap {
				opacity: 1;
			}

			&:after {
				opacity: .8;
			}
		}
	}
}
