.search-results {
	display: grid;
	gap: 2rem;
	list-style: none;
	padding-left: 0;

	.search-result {
		border-bottom: 1.5px solid $gray;
		padding-top: .5rem;
		padding-bottom: var(--fluid-h2);
	}
}


#search-list {
	.pagination {
		display: flex;
		justify-content: center;
		margin-top: 2rem;

		a.page-numbers {
			color: $dark-gray;
			text-decoration: none;
			font-weigh: 700;

			&:hover, &:focus, &:active {
				color: $primary-color;
			}

			&:active {
				color: $primary-color-dark;
			}
		}

		.page-numbers {
			border-right: 1.5px solid $dark-gray;
			padding-left: .5rem;
			padding-right: .5rem;
			margin: .5rem 0;

			&:last-child {
				border-right: none;
			}
		}


		.current {
			color: $primary-color;
			font-weight: bold;
		}

	}
}

.no-results {
	align-self: center;
	justify-self: center;
	text-align: center;
}
