/**
 * Parent and Core Style Imports
 *
 * Imports all core mixins and all template/component styles
 *
 * DO NO EDIT OR REMOVE THESE DIRECTLY.
 *
 **/

// Imports the core output styles from settings and the mixins/vars that come with settings
@import "core";

@include sanitizer-setter();
@include general-typography-setter();

//partials
@import "partials/fluid-typography";

// Modules
@import "Parent/scss/modules/supers/supers";
//@import "Parent/scss/components/fontawesome";

// Components
@import "components/buttons";
@import "components/links";
@import "components/navigation";
@import "components/footer";
@import "components/grids";
@import "components/sections";
@import "components/highlighted-resources";
@import "components/action-card";
@import "components/better-menu/better-menu";
@import "components/better-menu/better-modal";
@import "components/better-menu/better-menu-theme";
@import "components/better-menu/better-modal-theme";
@import "components/fancy-dots";
@import "components/body";
@import "components/accordion";
@import "components/search-components";
@import "components/forms";

// Templates
@import "templates/author";
@import "templates/front";
@import "templates/single-resources";
@import "templates/search";
@import "templates/essential-actions";
@import "templates/login";


.soc-dark {
	svg {
		height: calc( var(--icon-size) + 5px ) !important;
		color: $tertiary-color !important;
	}
}
