.author-page {

	.author-title {
		font-size: var(--fluid-body-sm) !important;
	}


	.content {
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: 2rem;

		.fancy-btn {
			margin-top: .5rem;
			align-self: flex-start;
			font-size: var(--fluid-body-sm);
		}
	}
}
