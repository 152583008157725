#essential-action-header {
	position: relative;
	padding-bottom: 20px;
	.grid {
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		justify-content: center;
		align-items: center;
		gap: 20px;
		z-index: 1;

		@include mq(1200px) {
			flex-flow: row nowrap;
			align-items: flex-start;
		}
		.content-wrap {
			width: 100%;
			max-width: 800px;
			@include mq(1200px) {
				width: 65%;
				max-width: unset;
			}
		}
		.image-wrap {
			max-width: 400px;
		}

	}
	.title-wrap {
		font-size: var(--fluid-h5);
		display: flex;
		align-items: center;
		margin-bottom: .25rem;
		.icon {
			display: block;
			height: 1.5em;
			width: 1.5em;
			flex-shrink: 0;

			img {
				height: 100%;
				object-fit: contain;
				object-position: center;
			}
		}

		.short-title {
			font-weight: 700;
			text-transform: uppercase;
			color: $primary-color;
		}
	}

	.main-title {
		font-size: var(--fluid-h3);
	}


	.fancy-dots {
		position: relative;
		width: 80%;
		margin: 0 auto;
		height: 150px;
		margin-top: 20px;

		@include mq(1600px) {
			position: absolute;
			bottom: -25%;
			left: 0;
			width: calc(50% - 600px);
			height: 80%;
		}
	}


}

.essential-archive-resources-h {
	padding-top: 20px;
}

#action-resources {
	.more-btn {
		justify-self: center;
		margin-top: 2rem;
	}
}
